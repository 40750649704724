import "leaflet/dist/leaflet.css";
import "./global.css";

import App from "./App.svelte";

const app = new App({
  target: document.body,
});

export default app;
